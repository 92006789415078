import {
    GET_APIS,
    SAVE_APIS,
    SET_USER_SESSION
} from "../actions";

const initialState = {

};

function rootReducer(state = initialState, action) {
    if (action?.type !== "SET_USER_SESSION") {
        console.log("reducer action", action);
    };
    state.loading = false;
    if (state.errors) {
        delete state.errors;
    };
    switch (action.type) {
        case SET_USER_SESSION:
            state = Object.assign({}, state, { userSession: action.userSession });
            return state;
        case GET_APIS:
            state = Object.assign({}, state, { apis: action.apis });
            return state;
        case SAVE_APIS:
            return state;
        default:
            return state;
    }
};

export default rootReducer