import { Button, Checkbox, Table, TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApis, saveApis } from "../app/actions";

export default function Application() {

    const dispatch = useDispatch()
    let apis = useSelector(state => state?.apis);
    const [rows, setRows] = useState([]);
    const [savedApis, setSavedApis] = useState([]);

    const handleCheckboxChange = (event, row) => {
        if (event.target.checked) {
            setSavedApis(prevState => [...prevState, row.id]);
        } else {
            setSavedApis(prevState => prevState.filter(id => id !== row.id));
        }
    };

    const handleSaveApis = () => {
        const items = savedApis.map(id => {
            const api = apis.find(api => api.id === id);
            return {
                PK: api.name,
                SK: api.id,
                ...(api.gsi1pk && { GSI1PK: api.gsi1pk }),
                ...(api.gsi1sk && { GSI1SK: api.gsi1sk })
            };
        });
        console.log('dispatching', items);
        dispatch(saveApis({ items }));
    }

    useEffect(() => {
        if (apis) {
            const updatedRows = apis.map(api => ({
                id: api.id,
                name: api.name,
                description: api.description
            }))
            console.log('updatedRows', updatedRows)
            setRows(updatedRows)
        }
    }, [apis])

    return (
        <React.Fragment
            key={'AZE' + rows.length}
        >
            <div style={{ paddingTop: '3%' }}>
                Application management tab
            </div>
            <Button
                onClick={() => dispatch(getApis())}
            >
                Get APIs
            </Button>
            <Table style={{ width: '50%', margin: 'auto', marginTop: '20px', marginBottom: '20px', backgroundColor: 'white', padding: '20px', boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)' }}>
                <TableBody>
                    <TableRow key={'apitableheaderrow'}>
                        <TableCell align="center" style={{ fontWeight: "bolder" }}>
                            Select
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: "bolder" }}>
                            Name
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: "bolder" }}>
                            ID
                        </TableCell>
                    </TableRow>
                    {rows?.map((row, index) => {
                        return (
                            <TableRow key={index + 'apirow'}>
                                <TableCell align="center">
                                    <Checkbox
                                        onChange={(event) => handleCheckboxChange(event, row)}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    {row.name}
                                </TableCell>
                                <TableCell align="center">
                                    {row.id}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            <Button
                onClick={() => handleSaveApis(apis)}
            >
                Save
            </Button>
        </React.Fragment>
    )
}
