import {
    GET_APIS,
    SET_USER_SESSION,
    LOADING,
    SAVE_APIS
} from "../actions";
import { Auth } from "aws-amplify";

const restfulResources = {
    api: '/api',
};

async function getFromServer(questObject, method) {
    let restfulResource = questObject.resource;
    let params = questObject.params;
    let responseCode = 0;

    let tokens = await Auth.currentSession();
    let token = tokens.idToken.jwtToken;
    let _url = process.env.REACT_APP_API_URL + restfulResources[restfulResource];
    let body = '';

    let fetchObject = {
        method: method,
        headers: {
            'Authorization': token,
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        }
    }

    if (method === 'GET') {
        if (params && params.id) {
            _url += '/' + params.id;
        } else if (params) {
            let keys = Object.keys(params);
            _url += '?';
            for (var i in keys) {
                let key = keys[i];
                let value = params[key];
                if (Array.isArray(value)) {
                    for (var j in value) {
                        _url += '&' + key + '=' + value[j];
                    }
                } else {
                    _url += '&' + key + '=' + value;
                };
            };
        };
    } else if (method === 'POST') {
        body = JSON.stringify(questObject.body);
        fetchObject.body = body;
    }

    console.log(_url, '_url');

    let reply = await fetch(_url, fetchObject)
        .then(function (response) {
            responseCode = response.status;
            console.log('middleware response', response);
            return response.json();

        })
        .then(function (json) {
            console.log("getFromServer then", json);
            return json;
        }).catch(err => {
            console.log('err', err, 'responseCode', responseCode);
            return { error: 'timeout', responseCode };
        });
    if (responseCode === 401 && reply.code === 'exp' && !questObject.retry) {
        questObject.retry = true;
        return await getFromServer(questObject);
    } else {
        reply.responseCode = responseCode;
        return reply;
    };
};

function truncateApiNames(objArray) {
    return objArray.map(obj => {
        const name = obj.name;
        const index = name.lastIndexOf("-");
        const truncatedName = index >= 0 ? name.slice(0, index) : name;
        return { ...obj, name: truncatedName };
    });
}

export function middleware({ dispatch }) {
    return function (next) {
        return async function (action) {
            if (action.type === SET_USER_SESSION) {
                console.log('setting user session');
            } else if (action.type === GET_APIS) {
                dispatch({ type: LOADING, loading: true });
                let questObject = {
                    resource: 'api',
                }
                let apis = await getFromServer(questObject, 'GET');
                apis.results = JSON.parse(apis?.results);
                action.apis = truncateApiNames(apis.results?.apis_with_authorizer)
            } else if (action.type === SAVE_APIS) {
                dispatch({ type: LOADING, loading: true });
                let questObject = {
                    resource: 'api',
                    body: action.data
                }
                let result = await getFromServer(questObject, 'POST');
                action.result = result;
            }
            return next(action)
        }
    }
}