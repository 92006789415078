export const LOADING = 'LOADING';
export const SET_USER_SESSION = 'SET_USER_SESSION';
export const GET_APIS = 'GET_APIS';
export const SAVE_APIS = 'SAVE_APIS';

export function setUserSession(userSession) {
    console.log("Action SET_USER_SESSION");
    return { type: SET_USER_SESSION, userSession };
};

export function getApis() {
    console.log("Action GET_APIS");
    return { type: GET_APIS };
};

export function saveApis(data) {
    console.log("Action SAVE_APIS", data);
    return { type: SAVE_APIS, data };
};