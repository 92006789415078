import React from "react";

export default function Splash(props){
    return(
        <React.Fragment>
            <div>
                Splash page
            </div>
        </React.Fragment>
    )
}