import './App.css';
import { useSelector } from 'react-redux';
import Login from './login';
import { Route, Routes } from 'react-router-dom';
import Splash from './splash';
import Admin from './admin';
import Application from './application';
import Users from './users';

function App() {
  const userSession = useSelector((state) => state.userSession)
  return (
    <div className="App">
      <Login open={!userSession} />
      <Routes>
      <Route path='/' element={<Splash />} />
      <Route path='/admin' element={<Admin />} />
      <Route path='/application/*' element={<Application />} />
      <Route path='/users/*' element={<Users />} />
      </Routes>
    </div>
  );
}

export default App;
