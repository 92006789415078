import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApis, saveApis } from "../app/actions";

export default function Users() {

    const dispatch = useDispatch()
    let apis = useSelector(state => state?.apis);
    let pk = null;
    let sk = null;
    let gsi1pk = null;
    let gsi1sk = null;

    const handleSaveApis = () => {
        let items = [{ PK: pk, SK: sk, GSI1PK: gsi1pk, GSI1SK: gsi1sk }]
        console.log('dispatching', items);
        dispatch(saveApis({ items }));
    }

    return (
        <React.Fragment>
            <div style={{ paddingTop: '3%' }}>
                User management tab
            </div>
            <Grid container style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <Grid item>
                    <TextField
                        onChange={(e) => pk = e.target.value}
                        placeholder="PK"
                    >
                        PK
                    </TextField>
                </Grid>
                <Grid item>
                    <TextField
                        onChange={(e) => sk = e.target.value}
                        placeholder="SK"
                    >
                        SK
                    </TextField>
                </Grid>
                <Grid item>
                    <TextField
                        onChange={(e) => gsi1pk = e.target.value}
                        placeholder="GSI1PK"
                    >
                        GSI1PK
                    </TextField>
                </Grid>
                <Grid item>
                    <TextField
                        onChange={(e) => gsi1sk = e.target.value}
                        placeholder="GSI1SK"
                    >
                        GSI1SK
                    </TextField>
                </Grid>
            </Grid>
            <Button
                onClick={() => handleSaveApis(apis)}
            >
                Save
            </Button>
        </React.Fragment>
    )
}
